.App {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 3em;
}

.content {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.content button {
  margin-top: 1em;
  margin-bottom: 1em;
}

.content textarea {
  height: 500px;
}